import React from 'react'
import Link from 'gatsby-link'
import classnames from 'classnames'
import styles from './index.module.css'

const Button = ({ children, to, rootStyle }) => (
  <Link to={to} className={classnames(styles.btn, rootStyle)}>
    {children}
  </Link>
)

export default Button
