import React from 'react'
import styles from './index.module.css'
import Container from '../../Container'
import InfoBanner from '../../InfoBanner'
import Button from '../../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import aggregatorImage from './assets/Solution_Img1.png'

const Aggregator = ({ homePage }) => {
  return (
    <>
      <section className={styles.Aggregator}>
        <Container>
          <h2 className={styles.title}>
            {homePage ? 'solutions' : 'super aggregator operator tier launcher'}
          </h2>
          {homePage && <h3>super aggregator operator tier launcher</h3>}
          <p className={styles.subtitle}>
            Streaming war is a challenge and an opportunity for those who look
            to make the TV simple again. Operators can become super-aggregators
            and offer a single interface to discover and browse contents from
            multiple sources
          </p>
          <figure>
            <img src={aggregatorImage} alt="Aggregator" />
          </figure>
          <p>
            iFeelsmart's unique solutions, experience and relationship with OTT
            provider make this promise a reality :
          </p>
          {homePage ? (
            <ul className={styles.cards}>
              <li>Single device</li>
              <li>Single remote control</li>
              <li>Integrated Digital Identity and SSO</li>
              <li>Unified launcher</li>
              <li>Unified billing</li>
            </ul>
          ) : (
            <div className={styles.cards}>
              <div className={styles.card}>Single device</div>
              <div className={styles.card}>Single remote control</div>
              <div className={styles.card}>
                Integrated Digital Identity and SSO
              </div>
              <div className={styles.card}>Unified launcher</div>
              <div className={styles.card}>Unified billing</div>
            </div>
          )}
          <p>
            iFeelsmart gives to the operator the full control of witch partner's
            content it wants to highlight in the launcher creating a unique real
            estate management position to increase ARPU with additional revenue
            share and advertising rvenues.
          </p>
          {homePage && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button to="/solutions/">View&nbsp;all&nbsp;solutions</Button>
            </div>
          )}
        </Container>
      </section>
      <InfoBanner rootStyle={styles.infoBanner}>
        OTT apps deeplink management
      </InfoBanner>
    </>
  )
}

export default Aggregator
